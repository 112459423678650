import { DatePicker, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Line from '../../../../components/Line';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IOperacoesPagarMeV4DTO } from '../../../../models/relatorio.model';
import { usePagarMeV4Service } from '../../../../services/pagarmeV4.service';
import { ExportToExcel } from '../../../../util/exportToExcel';
import TableColumns from './table-columns';
import TableColumnsOperacao from './table-columns-operacao';
import { useDadosExportExcel } from './hooks/use-dados-export-excel.hook';

const { RangePicker } = DatePicker;

function RelConciliacaoBancariaNew() {
	const { setIsGlobalLoading, unidade } = useGlobalContext();
	const [dadosPagarMeV4, setDadosPagarMeV4] = useState<IOperacoesPagarMeV4DTO[]>();
	const [dataInicio, setDataInicio] = useState<moment.Moment>();
	const [dataFim, setDataFim] = useState<moment.Moment>();
	const [data, setData] = useState<IOperacoesPagarMeV4DTO[]>([]);

	const servicePagarMeV4 = usePagarMeV4Service();

	const setaDataInicial = () => {
		const dataFimQuandoAbreATela = moment();
		setDataFim(dataFimQuandoAbreATela);

		const dataInicioQuandoAbreATela = moment().subtract(7, 'days');
		setDataInicio(dataInicioQuandoAbreATela);
	};

	useEffect(() => {
		setaDataInicial();
	}, [unidade.id]);

	const getPeriodo = (): any => {
		if (dataInicio && dataFim) {
			return [moment(dataInicio, 'YYYY-MM-DD'), moment(dataFim, 'YYYY-MM-DD')];
		}
		return null;
	};

	useEffect(() => {
		if (unidade && unidade.id && dataInicio && dataFim) {
			const recipientId = unidade.identificadorPagarMeV4;
			setIsGlobalLoading(true);

			servicePagarMeV4
				.operacoesNew(
					recipientId,
					dataFim.toISOString(),
					dataInicio.toISOString(),
				)
				.then((response) => {
					const sortedData = response.data.sort((a, b) => {
						return a?.operacao?.date_created > b?.operacao?.date_created
							? -1
							: a?.operacao?.date_created < b?.operacao?.date_created
								? 1
								: 0;
					});
					setDadosPagarMeV4(sortedData);
					setData(sortedData);
				})
				.catch((error) => console.log(error))
				.finally(() => setIsGlobalLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade.id, dataInicio, dataFim]);

	const dadosExportExcel = useDadosExportExcel({ data });

	const filterTransactions = (operacaoId) => {
		return dadosPagarMeV4.filter(item => item.operacao.oid === operacaoId);
	};

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<h3 className="title">Conciliação bancária</h3>
				<div className="space-between">
					<div className="flex flex-row items-end" style={{ marginBottom: 10 }}>
						<div className="datePicker">
							<span>Período</span>
							<br />
							<RangePicker
								value={getPeriodo()}
								onChange={(values) => {
									setDataInicio(values && values[0]);
									setDataFim(values && values[1]);
								}}
								format={'DD-MM-YYYY'}
							/>
						</div>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel apiData={dadosExportExcel} fileName={'Conciliação-bancaria'} />
					</div>
				</div>
				<Line />

				<Table
					dataSource={dadosPagarMeV4?.filter(item => !item.operacao.oid)}
					columns={TableColumnsOperacao()}
					pagination={false}
					rowClassName={(record) =>
						record.operacao.movement_object.object === 'transfer' ? 'saque' : ''
					}
					scroll={{ x: '100%' }}
					style={{ whiteSpace: "nowrap" }}
					rowKey={(record) => record.operacao.id}
					expandable={{
						expandedRowRender: (record) => (
							<Table
								dataSource={filterTransactions(record.operacao.id)}
								columns={TableColumns()}
								pagination={false}
								rowKey={(transaction) => transaction.operacao.id}
							/>
						),
					}}
				/>
			</div>
		</div>
	);
}

export default RelConciliacaoBancariaNew;
