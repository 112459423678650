import { TableColumnsType } from "antd";
import { formatLocaleDateString, formatMoney, formattedDate } from "../../../../util/format";
import { IOperacoesPagarMeV4DTO } from "../../../../models/relatorio.model";
import useTranslate from "./hooks/use-translation.hook";

const TableColumns = () => {
	const { translate } = useTranslate();

	const columns: TableColumnsType<IOperacoesPagarMeV4DTO> = [
		{
			title: 'Data de Criação',
			dataIndex: ['operacao', 'date_created'],
			align: "center",
			key: 'date_created',
			render: (text) => formatLocaleDateString(text),
		},
		{
			title: 'Valor',
			dataIndex: ['operacao', 'movement_object', 'amount'],
			align: "center",
			key: 'amount',
			render: (text, record) => {
				const valor = text / 100;
				const taxa = record.operacao.movement_object.fee / 100;
				const valorFinal = valor - taxa;
				return formatMoney(valorFinal) || "-";
			},
		},
		{
			title: 'Tipo pagamento',
			dataIndex: ['operacao', 'movement_object', 'payment_method'],
			align: "center",
			key: 'payment_method',
			render(_value, record, _index) {
				return translate(record.operacao.movement_object.payment_method);
			},
		},
		{
			title: 'Nome do Aluno',
			dataIndex: 'nomeAluno',
			align: "center",
			key: 'nomeAluno',
			render: (text) => text || "-",
		},
		{
			title: 'Nome do Responsável',
			dataIndex: 'nomeResponsavel',
			align: "center",
			key: 'nomeResponsavel',
			render: (text) => text || "-",
		},
		{
			title: 'Descrição do Curso',
			dataIndex: 'dsCurso',
			align: "center",
			key: 'dsCurso',
			render: (text) => text || "-",
		},
		{
			title: 'Data do Pagamento',
			dataIndex: 'dhPagamento',
			align: "center",
			key: 'dhPagamento',
			render: (text) => formattedDate(text),
		},
		{
			title: 'Valor da Cobrança',
			dataIndex: 'vlCobranca',
			align: "center",
			key: 'vlCobranca',
			render: (text) => formatMoney(text) || "-",
		},
		{
			title: 'Valor Pago',
			dataIndex: 'vlPago',
			align: "center",
			key: 'vlPago',
			render: (text) => formatMoney(text) || "-",
		},
		{
			title: 'Valor Sede',
			dataIndex: 'vlSede',
			align: "center",
			key: 'vlSede',
			render: (text) => formatMoney(text) || "-",
		},
		{
			title: 'Taxa',
			dataIndex: ['operacao', 'movement_object', 'fee'],
			align: "center",
			key: 'fee',
			render: (text) => formatMoney(text / 100) || "-",
		},
		{
			title: 'Valor Franquia',
			dataIndex: 'vlFranquia',
			align: "center",
			key: 'vlFranquia',
			render: (text) => formatMoney(text) || "-",
		},
		{
			title: 'Parcela',
			dataIndex: 'parcela',
			align: "center",
			key: 'parcela',
			render: (text) => text || "-",
		},
		{
			title: 'Operação',
			dataIndex: ['operacao', 'type'],
			align: "center",
			key: 'type',
			render(_value, record, _index) {
				return translate(record.operacao.type);
			},
		},
		{
			title: 'Status da Operação',
			dataIndex: ['operacao', 'status'],
			align: "center",
			key: 'status',
			render(_value, record, _index) {
				return translate(record.operacao.status);
			},
		},
		{
			title: 'Data Previsão',
			dataIndex: ['operacao', 'movement_object', 'funding_estimated_date'],
			align: "center",
			key: 'funding_estimated_date',
			render: (text) => formatLocaleDateString(text),
		},
		{
			title: 'Data transferido',
			dataIndex: ['operacao', 'movement_object', 'funding_date'],
			align: "center",
			key: 'funding_date',
			render: (text) => formatLocaleDateString(text),
		},
		{
			title: 'ID da Operação',
			dataIndex: ['operacao', 'id'],
			align: "center",
			key: 'id',
			render: (text) => text || "-",
		},
		{
			title: 'ID da Cobrança',
			dataIndex: 'cobrancaId',
			align: "center",
			key: 'cobrancaId',
			render: (text) => text || "-",
		},
		{
			title: 'ID do Saque',
			dataIndex: ['operacao', 'oid'],
			align: "center",
			key: 'oid',
			render: (text) => text || "-",
		},
		{
			title: 'Objeto de Movimento',
			dataIndex: ['operacao', 'movement_object', 'object'],
			align: "center",
			key: 'movement_object',
			render(_value, record, _index) {
				return translate(record.operacao.movement_object.object);
			},
		},
		{
			title: 'Status do Movimento',
			dataIndex: ['operacao', 'movement_object', 'status'],
			key: 'movement_status',
			render(_value, record, _index) {
				return translate(record.operacao.movement_object.status);
			},
		},
		{
			title: 'Data de Criação do Movimento',
			dataIndex: ['operacao', 'movement_object', 'date_created'],
			align: "center",
			key: 'movement_date_created',
			render: (text) => formatLocaleDateString(text),
		},
		{
			title: 'Nome da Unidade',
			dataIndex: 'nomeUnidade',
			align: "center",
			key: 'nomeUnidade',
			render: (text) => text || "-",
		},
		{
			title: 'Parcela Financeiro',
			dataIndex: 'parcelaFinanceiro',
			key: 'parcelaFinanceiro',
		},
		{
			title: 'Única',
			dataIndex: 'flUnica',
			align: "center",
			key: 'flUnica',
			render: (text) => text || "-",
		},
		// {
		// 	title: 'Status do Saque',
		// 	dataIndex: 'statusSaque',
		// 	align: "center",
		// 	key: 'statusSaque',
		// 	render(_value, record, _index) {
		// 		return translate(record.statusSaque);
		// 	},
		// },
	];

	return columns;
};

export default TableColumns;
